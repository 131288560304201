<!-- 功能：统一认证 -->
<template>
  <div id="index">
    <span id="message"></span>
  </div>
</template>

<script>
import pick from 'lodash.pick'
import { getTokenByCode, getUserInfo, getToken, getMarketToken, getEccomTokenFromMarket } from '@/api/user.js'
import { addOrUpdateUser, whetherAdministrator, updateAdministrator, addEccomAdministrator } from '@/api/api.js'
export default {
  name: 'Index',
  data() {
    return {
      userInfo: {},
      // userChanged: false,
      state: '',
      url: '',
    }
  },
  created() {
    this.url = window.location.href;
    const info = this.$route.query;
    // 有参数，存参数
    info['campaignId'] ? sessionStorage.setItem('campaignId', info['campaignId']) : null;
    info['userId'] ? sessionStorage.setItem('userId', info['userId']) : null;
    info['username'] ? sessionStorage.setItem('platform', info['username']) : null;
    info['type'] ? sessionStorage.setItem('type', info['type']) : null;
    info['toPath'] ? sessionStorage.setItem('toPath', info['toPath']) : null;
    info['redirectUri'] ? sessionStorage.setItem('redirectUri', info['redirectUri']) : null;
    info['paperId'] ? sessionStorage.setItem('paperId', info['paperId']) : null;
    const quesUri = decodeURIComponent(sessionStorage.getItem('toPath'));
    quesUri === '/paper' ? sessionStorage.removeItem('redirectUri') : null; // 预报名需要重定向
    
    const CLIENT_ID_MARKET_LIST = this.$g.CLIENT_ID_MARKET_LIST;
    if (CLIENT_ID_MARKET_LIST.includes(info.username)) {
      // 市场活动
      this.getMarketToken();
    } else {
      this.entrance();
    }
  },
  methods: {
    // 认证入口
    entrance () {
      let token = sessionStorage.getItem('token');
      if (token) { // 1 有token 获取用户信息 getUserInfo
        this.getUserInfo(token);
      } else { // 2 无token
        const code = this.$route.query.code;
        if (code) { // 2.1 有code 获取token getTokenByCode
          this.getTokenByCode(code);
        } else { // 2.2 无code toAuth
          this.toAuth();
        }
      }
    },

    // 获取市场活动的token
    async getMarketToken () {
      const { token } = await getMarketToken();
      if (token) {
        this.getEccomTokenFromMarket(token);
      } else {
        // 尝试自己去登录
        this.toAuth();
      }
    },

    // 通过市场活动获取认证系统token
    async getEccomTokenFromMarket (auth) {
      const parameter = {
        accountId: this.$route.query.userId
      }
      const { status, token } = await getEccomTokenFromMarket(parameter, auth);
      if (status === 1 && token) {
        sessionStorage.setItem('token', token); // 这个token可能是无效的
        this.getUserInfo(token);
      } else {
        this.toAuth();
      }
    },

    // 获取用户信息
    async getUserInfo(token) {
      let { code, data } = await getUserInfo(token);
      if (code === 200 && data) { // 1.1 token有效 成功 添加/更新 addUser
        const fields = ['ecoAccountId', 'accountAlias', 'enterpriseName', 'accountType', 'email', 'phone'];
        this.userInfo = pick(data, fields); // 用户信息
        sessionStorage.setItem('user', JSON.stringify(this.userInfo));

        this.$store.commit('setAccountId', this.userInfo.ecoAccountId);
        this.$store.commit('setAccountAlias', this.userInfo.accountAlias);
        this.$store.commit('setEnterpriseName', this.userInfo.enterpriseName);
        this.$store.commit('setAccountType', this.userInfo.accountType);
        this.$store.commit('setEmail', this.userInfo.email);
        this.$store.commit('setPhone', this.userInfo.phone);

        sessionStorage.setItem('userId', this.userInfo.ecoAccountId);
        this.getToken();
      } else { // 1.2 token无效 失败 删除token toAuth
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('LocalToken');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('store');
        this.toAuth();
      }
    },

    // 本系统认证
    async getToken() {
      let { code, data, message } = await getToken();
      if (code === 200) {
        sessionStorage.setItem('LocalToken', data.token);
        this.addUser();
      } else {
        this.$message({
          type: 'error',
          message: message
        })
      }
    },

    // 向问卷系统用户表添加当前用户(若无添加，有则更新)
    async addUser() {
      let { code, message } = await addOrUpdateUser(this.userInfo);
      if (code === 200) {
        // 直接访问
        // 如果是来设计问卷的，判断是否是管理员
        const toPath = sessionStorage.getItem('toPath');
        if (toPath === '/paper') {
          this.whetherAdministrator();
        } else if (toPath === '/answer') {
          // 跳转至回答问卷页面
          this.$router.push({ 
            path: '/answer',
            query: {
              redirectUri: this.$route.query.redirectUri,
              campaignId: this.$route.query.campaignId,
              type: this.$route.query.type,
              userId: this.$route.query.userId,
              username: this.$route.query.username,
            },
          });
        } else {
          this.$router.push({ path: '/paper' });
        }
      } else {
        this.$message({
          type: 'error',
          message: message
        })
      }
    },

    // 判断是否为管理员
    async whetherAdministrator() {
      const parameter = {
        email: this.userInfo.email
      }
      const { code, data } = await whetherAdministrator(parameter)
      if (code === 200) {
        // 是
        if (data.isAdmin && data.admin !== '2') {
          this.jump();
        } else if (data.isAdmin && data.admin === '2') {
          this.updateAdministrator(); // 修改管理员身份
        } else {
          this.addEccomAdministrator();// 添加至管理员表
        }
      } else {
        this.$message({
          type: 'error',
          message: '请求错误'
        })
      }
    },

    // 修改管理员身份为普通管理员
    async updateAdministrator() {
      const parameter = {
        userEcoEmail: this.userInfo.email,
        admin: 0
      }
      const { code } = await updateAdministrator(parameter);
      if (code === 200) {
        // 更新为普通管理员成功
        this.jump();
      }
    },

    // 添加至管理员表
    async addEccomAdministrator() {
      const parameter = {
        userEcoEmail: this.userInfo.email,
        admin: 0
      }
      const { code } = await addEccomAdministrator(parameter);
      if (code === 200) {
        // 添加至管理员表成功
        this.jump();
      }
    },

    // 跳转至认证系统
    toAuth() {
      const redirect_uri = encodeURIComponent(this.$g.AUTH_INDEX);
      location.href = `${this.$g.AUTHORIZE_NET}?response_type=code&client_id=${this.$g.CLIENT_ID}&redirect_uri=${redirect_uri}&state=authapi_index`;
    },

    // 跳转至指定页面
    jump() {
      let quesUri = sessionStorage.getItem('toPath');
      if (quesUri && quesUri !== 'undefined' && quesUri !== 'null') {
        this.$router.push({ path: quesUri });
      } else {
        this.$router.push({ path: '/paper' });
      }
    },

    // 获取token
    async getTokenByCode(code) {
      let { access_token } = await getTokenByCode(code);
      if (access_token) { // 2.1.1 code有效 成功 getUserInfo
        sessionStorage.setItem('token', access_token);
        // 获取用户信息
        this.getUserInfo(access_token);
      } else { // 2.1.2 code无效 失败(code反复使用) toAuth
        this.toAuth();
      }
    }
  },
}
</script>

<style>

</style>